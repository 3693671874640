// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.MuiGrid-root.login-wrapper.MuiGrid-container {
    height: 100vh;
}
.MuiGrid-root.login-right-content.MuiGrid-item {
    display: flex;
    align-items: center;
}
.login-content {
    width: 100%;
    max-width: 368px;
    margin-left: auto;
    margin-right: 95px;
}

.icon-show-cust span {
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/containers/styles/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["main.MuiGrid-root.login-wrapper.MuiGrid-container {\n    height: 100vh;\n}\n.MuiGrid-root.login-right-content.MuiGrid-item {\n    display: flex;\n    align-items: center;\n}\n.login-content {\n    width: 100%;\n    max-width: 368px;\n    margin-left: auto;\n    margin-right: 95px;\n}\n\n.icon-show-cust span {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
