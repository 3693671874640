// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-top {
    padding: 5px 25px 20px 13px;
}
.header-top img.logo {
    width: 128px;
    height: auto;
}
.header-top .user-icon .MuiAvatar-root {
    width: 32px;
    height: 32px;
    font-size: 13px;
    margin-right: 0;
}
.user-icon-popup .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    margin-top: 49px;
    box-shadow: none;
    border: 1px solid #dadada;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/headerComponents.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".header-top {\n    padding: 5px 25px 20px 13px;\n}\n.header-top img.logo {\n    width: 128px;\n    height: auto;\n}\n.header-top .user-icon .MuiAvatar-root {\n    width: 32px;\n    height: 32px;\n    font-size: 13px;\n    margin-right: 0;\n}\n.user-icon-popup .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {\n    margin-top: 49px;\n    box-shadow: none;\n    border: 1px solid #dadada;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
