// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-menu {
    background: #1f236f;
    width: 103px;
    max-width: 103px;
}
.side-menu .MuiSvgIcon-root {
    color: #fff;
}
.side-menu .MuiListItemText-root {
    color: #fff;
    width: 100%;
}
.side-menu .MuiListItemIcon-root {
    background: rgba(255, 255, 255, 0.25);
    text-align: center;
    padding: 14px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
}
.side-menu .MuiListItem-root {
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 8px;
    opacity: 0.65;
}
.side-menu .MuiTypography-displayBlock {
    font-size: 10px;
}
.side-menu .MuiListItem-root:hover, .side-menu a.active .MuiListItem-root {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/siderComponents.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,UAAU;AACd","sourcesContent":[".side-menu {\n    background: #1f236f;\n    width: 103px;\n    max-width: 103px;\n}\n.side-menu .MuiSvgIcon-root {\n    color: #fff;\n}\n.side-menu .MuiListItemText-root {\n    color: #fff;\n    width: 100%;\n}\n.side-menu .MuiListItemIcon-root {\n    background: rgba(255, 255, 255, 0.25);\n    text-align: center;\n    padding: 14px;\n    border-radius: 50%;\n    margin: auto;\n    margin-bottom: 10px;\n}\n.side-menu .MuiListItem-root {\n    flex-wrap: wrap;\n    text-align: center;\n    margin-bottom: 8px;\n    opacity: 0.65;\n}\n.side-menu .MuiTypography-displayBlock {\n    font-size: 10px;\n}\n.side-menu .MuiListItem-root:hover, .side-menu a.active .MuiListItem-root {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
