// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .MuiContainer-root {
    margin-left: 0;
}
body main {
    padding-left: 18px;
}
.MuiTableCell-root {
    font-size: 14px;
}
th.MuiTableCell-root.MuiTableCell-head span {
    font-weight: 600;
}
th.MuiTableCell-root.MuiTableCell-head {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/global-styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":["body .MuiContainer-root {\n    margin-left: 0;\n}\nbody main {\n    padding-left: 18px;\n}\n.MuiTableCell-root {\n    font-size: 14px;\n}\nth.MuiTableCell-root.MuiTableCell-head span {\n    font-weight: 600;\n}\nth.MuiTableCell-root.MuiTableCell-head {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
